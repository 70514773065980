import React from 'react'

import './Projects.css'

import Content from '../content/Content'
import SliderCommon from '../sliders/Sliders'
import projectsData from '../../data/projects.json'

const headerText = 'Projetos realizados'
const subHeaderText = 'Veja os projetos que nosso time já realizou'

const Projects = () => 
	<Content>
		<div className="containerProjects">
			<div className="containerProjectsBlock">
				<h1 className="containerProjectsHeader">
					{headerText}
				</h1>
				<h2 className="containerProjectsSubHeader">
					{subHeaderText}	
				</h2>
			</div>
			<div className="containerProjectsBlock"></div>
			<div className="containerProjectsSlider">
				<SliderCommon data={projectsData}/>
			</div>
		</div>
	</Content>


export default Projects
