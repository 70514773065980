import React from 'react'
import { Link } from 'react-router-dom'

import './Header.css'

const RenderLinkItem = props =>
	<div className="headerContainerItem">
		<Link to={props.link}>
			{props.name}
		</Link>
	</div>

const Header = () => 
	<header>
		<div className="headerContainer">
			<div className="headerContainerItems">
				<RenderLinkItem name="Sobre" link="/sobre" />	
				<RenderLinkItem name="Projetos" link="/projetos"/>	
				<RenderLinkItem name="Contato" link="/contato" />	
			</div>
		</div>
		<div className="headerContainerBrand">
			<Link to='/'>
				Apps Atma			
			</Link>
		</div>
	</header>

export default Header
