import React from 'react'

import './Content.css'

const Content = props =>
	<div className="containerContent">
		<div className="containerContentMargin"> 
			{props.children}
		</div>
	</div>

export default Content
