import React from 'react'

import './ButtonCommon.css'

const ButtonCommon = props => 
	<div>
		<button className="buttonCommon"
			onClick={props.onClick}
		>
			{props.name}
		</button>
	</div>

export default ButtonCommon
