import React from 'react'

import './Politics.css'

import bitcoinPrice from '../../politics/bitcoinPrice'
import calendarioBolsaFamilia from '../../politics/calendarioBolsaFamilia'
import consultaPlacaNovo from '../../politics/consultaPlacaNovo'
import frasesDeAmor from '../../politics/frasesDeAmor'
import frasesDeMotivacao from '../../politics/frasesDeMotivacao'
import iGeekFlix from '../../politics/iGeekFlix'
import receitasDeLiquidificador from '../../politics/receitasDeLiquidificador'
import simuladorDeEmprestimos from '../../politics/simuladorDeEmprestimos'
import consultaCpf from '../../politics/consultaCpf'
import consultaCnpj from '../../politics/consultaCnpj'
import emprestimoOnline from '../../politics/emprestimoOnline'
import dns from '../../politics/dns'
import rastreio from '../../politics/rastreio'
import cleanCache from '../../politics/cleanCache'
import instav from '../../politics/instav'
import qrcode from '../../politics/qrcode'
import xlsxViewer from '../../politics/xlsxViewer'
import memes from '../../politics/memes'

import Content from '../content/Content'

const headerText = 'Políticas de Privacidade'
const subHeaderText = 'Entenda como funciona nossa politica de privacidade'

const getPolitics = name => {
	switch(name) {
		case 'bitcoin-price': return bitcoinPrice
		case 'calendario-bolsa-familia': return calendarioBolsaFamilia
		case 'consulta-placa-novo' : return consultaPlacaNovo
		case 'frases-de-amor': return frasesDeAmor
		case 'frases-de-motivacao': return frasesDeMotivacao
		case 'igeekflix': return iGeekFlix
		case 'receitas-de-liquidificador': return receitasDeLiquidificador
		case 'simulador-de-emprestimos': return simuladorDeEmprestimos
		case 'emprestimo-online': return emprestimoOnline
		case 'consulta-cpf': return consultaCpf
		case 'consulta-cnpj': return consultaCnpj
		case 'rastreio-de-encomendas': return rastreio
		case 'dns-speed-test': return dns
		case 'clean-cache': return cleanCache
		case 'instav': return instav
		case 'qrcode': return qrcode
		case 'xlsxviewer': return xlsxViewer
		case 'memes': return memes
		default: return null
	}
}

const Politics = props => { 
	let politics
	
	const pathname = props.location
		? props.location.pathname
		: '' 

	if (pathname.startsWith('/politica-de-privacidade/')) {
		const params = pathname.split('/')[2]
		politics = getPolitics(params)
		if (politics === null) {
			props.history.push('/Main')
		}	
	} else {
		props.history.push('/Main')
	}
	
	return (
		<Content>
			<div className="containerPolitics">
				<div className="containerPoliticsBlock">
					<h1 className="containerPoliticsHeader">
						{headerText}
					</h1>
					<h2 className="containerPoliticsSubHeader">
						{subHeaderText}	
					</h2>
					<p className="containerPoliticsText" 
						dangerouslySetInnerHTML={{__html: politics}}>
					</p>
				</div>
				<div className="containerPoliticsBlock"></div>
			</div>
		</Content>
	)
}

export default Politics
