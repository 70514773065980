import React from 'react'

import './Contact.css'

import ButtonCommon from '../buttons/ButtonCommon'
import Content from '../content/Content'

const headerText = 'Entre em contato'
let subHeaderText = 'Tem alguma dúvida, ou gostaria de entrar em '
	subHeaderText += 'contato conosco ? Preencha o formulário abaixo '
	subHeaderText += 'e retornaremos o mais breve possível.'

const LabelInput = props =>
	<div className="containerContactLabelInput">
		{props.text}
	</div>

const ContactGroup = props =>
	<div className="containerContactGroup">
		{props.children}	
	</div>

const Contact = () =>
	<Content>
		<div className="containerContact">
			<div className="containerContactBlock">
				<h1 className="containerContactHeader">
					{headerText}
				</h1>
				<p className="containerContactText">
					{subHeaderText}	
				</p>
			</div>
			<div className="containerContactBlock"></div>
			<div className="containerContactForm">
				<ContactGroup>
					<LabelInput text="Nome"/>				
					<input className="containerContactInput" />
				</ContactGroup>
				<ContactGroup>
					<LabelInput text="E-Mail"/>				
					<input className="containerContactInput" />
				</ContactGroup>
				<ContactGroup>
					<LabelInput text="Mensagem"/>				
					<textarea className="containerContactTextArea"/>
				</ContactGroup>
				<div className="containerContactButton">
					<ButtonCommon name="ENVIAR"/>
				</div>
			</div>
		</div>
	</Content>

export default Contact
