import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import './App.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Routes from './Routes'

import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'

const App = () =>
	<Router>
		<Header />
		<Routes />
		<Footer />
	</Router>

export default App
