import React from 'react'

import { Switch, Route, Redirect } from 'react-router'

import About from '../components/about/About'
import Contact from '../components/contact/Contact'
import Main from '../components/main/Main'
import Politics from '../components/politics/Politics'
import Projects from '../components/projects/Projects'

export default props =>
	<Switch>
		<Route exact path='/' component={Main} />
		<Route path='/sobre' component={About} />
		<Route path='/contato' component={Contact} />
		<Route path='/projetos' component={Projects} />
		<Route path='/politica-de-privacidade/:text' component={Politics} />
		<Redirect from='*' to='/' />
	</Switch>
