import React from 'react'

import './About.css'

import Content from '../content/Content'

const headerText = 'Somos uma equipe de desenvolvimento de apps'
const subHeaderText = 'Nós utilizamos as principais tecnologias do mercado'
let aboutText = 'Através de análise e pesquisa, nossa equipe utiliza as melhores '
	aboutText += 'tecnologias do mercado para atendê-lo, as mesmas tecnologias que '
	aboutText += 'empresas como airbnb, facebook, uber utilizam.'

	

const About = () => 
	<Content>
		<div className="containerAbout">
			<div className="containerAboutBlock">
				<h1 className="containerAboutHeader">
					{headerText}
				</h1>
				<h2 className="containerAboutSubHeader">
					{subHeaderText}	
				</h2>
				<p className="containerAboutText">
					{aboutText}
				</p>
			</div>
			<div className="containerAboutBlock"></div>
		</div>
	</Content>


export default About
