import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import './Footer.css'

const actualYear = moment().format('YYYY')

const Footer = () =>
	<footer>
		<Link to='/'>
			Apps Atma ® {actualYear}
		</Link>	
	</footer>

export default Footer
