import React from 'react'

import './Main.css'

import ButtonCommon from '../buttons/ButtonCommon'
import Content from '../content/Content'

const headerText = 'Desenvolvimento de Aplicativos'
let subHeaderText = 'Desenvolvemos aplicativos especialmentes formulados '
	subHeaderText += 'para você, saiba mais sobre nós clicando no botão abaixo.'


const Main = props => { 

	const handleClick = () => {
		props.history.push('/sobre')	
	}

	return (
		<Content>
			<div className="containerMain">
				<div className="containerMainBlock">
					<h1 className="containerMainHeader">
						{headerText}
					</h1>
					<h2 className="containerMainSubHeader">
						{subHeaderText}	
					</h2>
				</div>
				<div className="containerMainBlock"></div>
			</div>
			<div className="containerButton">
				<ButtonCommon name="ACESSAR"
					onClick={handleClick}/>
			</div>
		</Content>
	)
}

export default Main
