import React from 'react'
import Slider from 'react-slick'

import consultaPlacaNovo from '../../assets/imgs/consultaPlacaNovo.png'
import iGeekFlix from '../../assets/imgs/iGeekFlix.png'
import bitcoinPrice from '../../assets/imgs/bitcoinPrice.png'
import frasesDeAmor from '../../assets/imgs/frasesDeAmor.png'
import frasesDeMotivacao from '../../assets/imgs/frasesDeMotivacao.png'
import simuladorDeEmprestimos from '../../assets/imgs/simuladorDeEmprestimos.png'
import receitasDeLiquidificador from '../../assets/imgs/receitasDeLiquidificador.png'

import './Sliders.css'


const getImage = name => {
	switch(name) {
		case 'consultaPlacaNovo': return consultaPlacaNovo
		case 'iGeekFlix': return iGeekFlix
		case 'bitcoinPrice': return bitcoinPrice
		case 'frasesDeAmor': return frasesDeAmor
		case 'frasesDeMotivacao': return frasesDeMotivacao
		case 'simuladorDeEmprestimos': return simuladorDeEmprestimos
		case 'receitasDeLiquidificador': return receitasDeLiquidificador
		default: return null
	}
}

const settings = {
	className: "slider",
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
}

const SliderItem = props => 
	<div className="sliderItem">
		<div className="sliderItemHeader">
			{props.data.name}
		</div>
		<div className="sliderItemContent">
			<div className="sliderItemContentImage">
				<img src={getImage(props.data.nameProject)} 
					alt={props.data.name}
					className="sliderItemContentImageSource"/> 
			</div>
			<div className="sliderItemContentText">
				{props.data.content}
			</div>
		</div>
		<div className="sliderItemFooter">
			<a href={props.data.link} className="sliderItemLink">
				Acesse aqui
			</a>
		</div>
	</div>

const SliderCommon = props => {

	const data = props.data 
		? 
			props.data.map(d => (
				<SliderItem data={d} key={d.projectName}/>
			)) 
		: null

	return (
		<Slider  { ...settings}>
			{data}
		</Slider>
	)	
}

export default SliderCommon
